import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("../views/index/index.vue"),
  },
  {
    path: "/localcuisine",
    name: "localcuisine",
    component: () => import("../views/localcuisine/localcuisine.vue"),
  },
  {
    path: "/gourmetdetails/:id",
    name: "gourmetdetails",
    component: () => import("../views/gourmetdetails/gourmetdetails.vue"),
  },
  {
    path: "/gushibeauty/:id",
    name: "gushibeauty",
    component: () => import("../views/gushibeauty/gushibeauty.vue"),
  },
  {
    path: "/gushibeauty",
    name: "gushibeauty",
    component: () => import("../views/gushibeauty/gushibeauty.vue"),
  },
  {
    path: "/scenicdetails/:id",
    name: "scenicdetails",
    component: () => import("../views/scenicdetails/scenicdetails.vue"),
  },
  {
    path: "/charmgushi",
    name: "charmgushi",
    component: () => import("../views/charmgushi/charmgushi.vue"),
  },
  {
    path: "/newsdetail/:id",
    name: "newsdetail",
    component: () => import("../views/newsdetail/newsdetail.vue"),
  },
  {
    path: "/news/:id/:pid",
    name: "news",
    component: () => import("../views/news/news.vue"),
  },
  {
    path: "/search",
    name: "search",
    component: () => import("../views/search/search.vue"),
  },
  {
    path: "/newsinfo/:id",
    name: "newsinfo",
    component: () => import("../views/newsinfo/newsinfo.vue"),
  },
  {
    path: "/gushiys/:id",
    name: "gushiys",
    component: () => import("../views/gushiys/gushiys.vue"),
  },
  {
    path: "/password",
    name: "password",
    component: () => import("../views/password/password.vue"),
  },
  {
    path: "/user",
    name: "user",
    component: () => import("../views/user/user.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/register/register.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/login.vue"),
  },
];
const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
